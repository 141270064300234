//
// boostraping all custom style app
//

.form-control.form-control-solid {
  background-color: #ededed;
  border-color: var(--bs-gray-100);
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
  &::placeholder {
    color: var(--bs-gray-700);
  }
}

.span-btn-hover {
  &:hover {
    border-radius: 50%;
    background: var(--bs-gray-300);
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3);
  // grid-template-rows: repeat(8, 50px);
  grid-auto-flow: dense;
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar {
  background-color: #131313;
  border-right: 0 !important;
}

.form-select.form-select-solid {
  background-color: #ededed;
  border-color: var(--bs-gray-100);
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ededed inset;
}

.eform--input--control {
  @apply w-[100%] p-3 rounded-md bg-[#ededed] border-[1px] text-base border-gray-300;

  &:focus {
    @apply border-blue-500 border-2 outline-none bg-white;
  }
}

.eform--input--control--readonly {
  @apply w-[100%] p-3 rounded-md bg-[#ededed] border-[1px] text-base border-gray-300;

  &:focus {
    @apply border-[1px] text-base border-gray-300;
  }
}

// @import './home/home';
@import './widgets/custom_modal/custom.modal';
