/* Generate classes h-[1-100]hv */
$prefix: 'h-';
$className: '';
$rangeStart: 1;
$rangeEnd: 100;

@for $i from $rangeStart through $rangeEnd {
  $className: #{$prefix + $i + 'vh'};
  .#{$className} {
    height: #{$i}vh;
  }
}
