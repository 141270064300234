@import 'bootstrap';

$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

.custom_widget_modal {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(#333, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;

  .custom_widget_modal_close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .custom_widget_modal_item {
    @extend .rounded, .overflow-hidden, .position-relative;
    background-color: white;
    width: 70%;
    max-height: 60vh;

    @media (max-width: $mediaMaxWidth) {
    }
  }
}
