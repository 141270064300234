// Global Variables
:root {
  --color-first: #ff6c6c;
  --color-second: #fff;
  --color-third: #fff;
  --color-forth: #ff6c6c;
  --block-width: 300px;
  --block-height: 270px;
  --border-width: 0.625rem;
  --border-radius-outer: 8px;
  --border-radius-inner: calc(var(--border-radius-outer) / 2);
  --font-plain: 'IBM Plex Sans', sans-serif;
  --font-special: 'Fredoka One', sans-serif;

  box-sizing: border-box;
  line-height: 1.4;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.banner {
  height: 100vh;
  overflow: hidden;
}

.carousel-indicators {
  width: 100%;
  margin: 0;
  left: 0;
  height: 5vh;
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
}

.carousel-caption {
  width: 100%;
  margin: 0;
  left: 0;
  height: 30vh;
  bottom: -5vh;
  margin: 0;
  background: rgba(0, 0, 0, 0.35);
}

.custom-carousel-control-next-icon {
  position: absolute;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: calc(0vh + 10vh);
  background-color: white;
  border-radius: 1000px;
  color: black;
}

.custom-carousel-control-prev-icon {
  position: absolute;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: calc(0vh + 10vh);
  background-color: white;
  border-radius: 1000px;
}

.brand-title {
  color: #fff;
  font-family: Intro;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.041px;
}

.brand-sub-title {
  color: #fff;
  font-family: Intro;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.027px;
}
.rainbow {
  width: 100%;
  height: 100%;
  animation: o-rotate-360 linear 8s infinite;

  span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(-50%, -50%);

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 100%;
    }

    &:first-child {
      background: var(--color-first);
      &:after {
        background: var(--color-second);
      }
    }

    &:last-child {
      background: var(--color-third);
      &:after {
        background: var(--color-forth);
      }
    }
  }
}

.c-subscribe-box {
  width: var(--block-width);
  height: var(--block-height);
  overflow: hidden;
  position: relative;
  box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
  border-radius: var(--border-radius-outer);

  &__wrapper {
    width: calc(100% - var(--border-width));
    height: calc(100% - var(--border-width));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.2rem 1rem 1.8rem;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-inner);
  }

  &__title {
    font-size: 1.6rem;
  }

  &__desc {
    font-size: 0.935rem;
    margin: 0.7rem auto 1.8rem;
    max-width: 240px;
  }

  &__form {
    margin-top: auto;
  }
}

.modal-content {
  margin-bottom: 10vh;
}

// Objects
@keyframes o-rotate-360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
