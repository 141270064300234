.eform--input--control {
  @apply w-[100%] p-3 rounded-md bg-[#ededed] border-[1px] text-base border-gray-300;

  &:focus {
    @apply border-blue-500 border-2 outline-none bg-white;
  }
}

.eform--input--control--readonly {
  @apply w-[100%] p-3 rounded-md bg-[#ededed] border-[1px] text-base border-gray-300;

  &:focus {
    @apply border-[1px] text-base border-gray-300;
  }
}